'use client'
import React from 'react'
import Hamburger from '@/app/components/ui/hamburger';
import { useDrawer } from '@/app/components/drawer-views/context';

const DrawerButton = () => {
  const { openDrawer } = useDrawer();
  return (

    <Hamburger onClick={() => openDrawer('MOBILE_MENU')} />
  )
}

export default DrawerButton