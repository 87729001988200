'use client';
import dynamic from 'next/dynamic';

import { ModalProvider } from '@/app/components/modal-views/context';
import ModalsContainer from '@/app/components/modal-views/container';
import DrawersContainer from '@/app/components/drawer-views/container';
import { Toaster } from 'react-hot-toast';

import Header from '@/app/components/layouts/_header';
import { Sidebar } from '@/app/components/layouts/_layout-sidebar';
import Footer from '@/app/components/layouts/_footer';
import { Suspense } from 'react';

const BottomNavigation = dynamic(
  () => import('@/app/components/layouts/_bottom-navigation'),
);

export default function Layout({ children }: React.PropsWithChildren<{}>) {
  return (
    <ModalProvider>
      <div className="flex min-h-screen w-full h-auto pl-0 lg:pl-20 flex-col bg-light-300 dark:bg-dark-100 mt-0">
        <Suspense>
          <Header />
        </Suspense>

        <div className="flex flex-1">
          <main className="flex w-full flex-col  min-h-[1200px]">
            <Sidebar />
            <div className="lg:mx-5 lg:mt-5">{children}</div>
          </main>
        </div>
        <div className="lg:mx-5">
          <Suspense>
            <Footer />
          </Suspense>
        </div>
        <Suspense>
          <BottomNavigation />
        </Suspense>
      </div>
      <ModalsContainer />
      <DrawersContainer />
      <Toaster containerClassName="!top-16 sm:!top-3.5 !bottom-16 sm:!bottom-3.5" />
    </ModalProvider>
  );
}
