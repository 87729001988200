// icon:social | Tabler Icons https://tablericons.com/ | Csaba Kissi
import * as React from "react";

export function SocialHubIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      viewBox="0 0 24 24"

      {...props}
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <path d="M14 5 A2 2 0 0 1 12 7 A2 2 0 0 1 10 5 A2 2 0 0 1 14 5 z" />
      <path d="M7 19 A2 2 0 0 1 5 21 A2 2 0 0 1 3 19 A2 2 0 0 1 7 19 z" />
      <path d="M21 19 A2 2 0 0 1 19 21 A2 2 0 0 1 17 19 A2 2 0 0 1 21 19 z" />
      <path d="M15 14 A3 3 0 0 1 12 17 A3 3 0 0 1 9 14 A3 3 0 0 1 15 14 z" />
      <path d="M12 7v4M6.7 17.8l2.8-2M17.3 17.8l-2.8-2" />
    </svg>
  );
}

