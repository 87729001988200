'use client';
import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { getProductSearchedData } from '@/app/product/[slug]/page-content/actions';
import { CloseIcon } from '@/app/components/icons/close-icon';
import { SpinnerIcon } from '@/app/components/icons/spinner-icon';

const HeaderSearchInput = () => {
  const [txtSearch, setTxtSearch] = useState();
  const [showSearchDrop, setShowSearchDrop] = useState(false);
  const [searchData, setSearchData] = useState();
  const [searching, setSearching] = useState(false);

  const searchInputChange = (txt) => {
    setTxtSearch(txt);
  };

  useEffect(() => {
    const getSearchData = async (txt) => {
      const { data, loading } = await getProductSearchedData(txt);
      setSearchData(data);
      setSearching(false);
      return { data };
    };

    if (txtSearch?.trim()?.length > 0) {
      setSearching(true);
      setSearchData(null);
      setShowSearchDrop(true);
      getSearchData(txtSearch);
    } else {
      setShowSearchDrop(false);
      //   setSearchData(null);
    }
  }, [txtSearch]);

  return (
    <>
      <input
        className="w-[292px] [border:none] [outline:none] font-light font-interstate text-sm bg-[transparent] h-[17px] relative placeholder:text-gray-800 text-center inline-block z-[1] "
        placeholder="Search giveaways, friends and community"
        type="text"
        autoComplete="off"
        value={txtSearch || ''}
        onChange={(e) => searchInputChange(e.target.value)}
      />

      <div className="absolute top-9 z-50 w-screen max-w-3xl">
        <div className="container">
          {showSearchDrop && (
            <div className="d-block bg-white h-[500px] max-h-[500px] overflow-scroll">
              <ul>
                <li className="flex justify-between  p-1 text-dark bg-gray-100 ">
                  {searching && (
                    <span className="flex  w-full p-2 px-4   ">
                      <SpinnerIcon className=" h-auto w-6 animate-spin mr-4" />
                      Searching...
                    </span>
                  )}

                  {!searching && searchData?.products?.nodes?.length == 0 && (
                    <span className=" p-2 px-5 text-dark text-sm ">
                      No Matched Product Found
                    </span>
                  )}

                  {!searching && searchData?.products?.nodes?.length > 0 && (
                    <span className=" p-2 px-5 text-sm ">
                      {`${searchData?.products?.nodes?.length}`} Matched Product
                      Found
                    </span>
                  )}

                  <span
                    className="cursor-pointer flex items-center px-2 hover:text-red-500 text-dark font-bold"
                    onClick={() => setTxtSearch('')}
                  >
                    <CloseIcon width={20} height={20} />
                  </span>
                </li>

                {!searching &&
                  searchData?.products?.nodes?.map((p, i) => (
                    <li
                      key={i}
                      className="p-1 px-4 hover:bg-brand hover:text-dark"
                      onClick={() => setTxtSearch('')}
                    >
                      <Link
                        className="text-dark text-sm"
                        href={`/product/${p?.slug}`}
                      >
                        {p?.name}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default HeaderSearchInput;
