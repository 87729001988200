import React from 'react';
import ActiveLink from '@/app/components/ui/links/active-link';
import { HomeIcon2 } from '@/app/components/icons/home-icon';
import { IconJoystick } from '@/app/components/icons/joy-stick-icon';
import { GiftBoxIcon2 } from '@/app/components/icons/gift-box';

import { StarPointsIcon } from '@/app/components/icons/star-point-icon';
import { TrophyIcon } from '@/app/components/icons/trophy-icon';
import { BroadcastIcon } from '@/app/components/icons/broadcast-icon';
import { SocialHubIcon } from '@/app/components/icons/social-hub-icon';
import { ShoppingBagsIcon } from '@/app/components/icons/shoppin-bag-icon';
import { LeaderboardIcon } from '@/app/components/icons/leaderboard-icon';
import { MedalIcon } from '@/app/components/icons/medal-icon';
import { HelpIcon } from '@/app/components/icons/help-icon';
import { DownloadIcon } from '@/app/components/icons/download-icon';
import { NewspaperOutlineIcon } from '@/app/components/icons/news-paper-icon';
import Hamburger from '@/app/components/ui/hamburger';
import { PeopleIcon } from '../icons/people-icon';

interface NavLinkProps {
  href: string;
  title: string;
  icon: React.ReactNode;
  isCollapse?: boolean;
}

interface SubNavLinkProps {
  href: string;
  title: React.ReactNode;
  icon: React.ReactNode;
  isCollapse?: boolean;
}

function NavLink({ href, icon, title }: NavLinkProps) {
  return (
    <ActiveLink
      href={href}
      className="my-0.5 flex items-center gap-1 pl-5 py-1 hover:bg-light-300 hover:dark:bg-dark-300 xs:px-6 sm:my-1 sm:gap-1.5 sm:px-7 lg:gap-2 xl:my-0.5"
      // activeClassName="text-dark-100 active-text-dark dark:active-text-light dark:text-light-400 font-medium bg-light-400 dark:bg-dark-400 hover:bg-light-600 hover:dark:bg-dark-500"
    >
      {icon && (
        <span className="flex flex-shrink-0 items-center justify-start w-auto xl:w-8">
          {icon}
        </span>
      )}
      <span className="text-dark-100 dark:text-light-400 text-sm inline-flex whitespace-nowrap overflow-hidden pl-3 lg:pl-1 ">
        {title}
      </span>
    </ActiveLink>
  );
}

function GroupHeader({ href, icon, title }: NavLinkProps) {
  return (
    <ActiveLink
      href={href}
      className="my-0.5 flex group-hover:lg:flex lg:hidden items-center gap-1 pl-5 py-1 hover:bg-light-300 hover:dark:bg-dark-300 xs:px-6 sm:my-1 sm:gap-1.5 sm:px-7 lg:gap-2 xl:my-0.5"
      // activeClassName="text-dark-100 active-text-dark dark:active-text-light dark:text-light-400 font-medium bg-light-400 dark:bg-dark-400 hover:bg-light-600 hover:dark:bg-dark-500"
    >
      <span className="text-dark-100 dark:text-light-400 text-sm inline-flex whitespace-nowrap overflow-hidden pl-3 lg:pl-1 ">
        {title}
      </span>
    </ActiveLink>
  );
}

function SubNavLink({ href, icon, title }: SubNavLinkProps) {
  return (
    <ActiveLink
      href={href}
      className="my-0.5 flex items-center gap-1 pl-5 py-1 hover:bg-light-300 hover:dark:bg-dark-300 xs:px-6 sm:my-1 sm:gap-1.5 sm:px-7 lg:gap-2 xl:my-0.5"
    >
      <span className="flex flex-shrink-0 items-center justify-start w-auto xl:w-8">
        {icon}
      </span>
      <span
        className={
          'text-dark-100 dark:text-light-400 text-sm inline-flex whitespace-nowrap overflow-x-hidden pl-3 lg:pl-1'
        }
      >
        {title}
      </span>
    </ActiveLink>
  );
}

const MenuItems = () => {
  return (
    <div className="flex h-full w-full flex-col">
      <div className="hidden lg:flex pl-6 lg:pb-2 xl:pb-10">
        {/* menu expand button */}
        {/* <Hamburger /> */}
      </div>
      <nav className="flex flex-col mb-2">
        <NavLink
          title={'Home'}
          href={'/'}
          icon={<HomeIcon2 className="h-[18px] w-[18px] text-brand text-sm" />}
        />
        <NavLink
          title={'Giveaways'}
          href={'/'}
          icon={
            <GiftBoxIcon2 className="h-[18px] w-[18px] text-current text-sm  hover:text-brand" />
          }
        />
        <NavLink
          title={'How To Play'}
          href={'/howtoplay'}
          icon={
            <IconJoystick className="h-[18px] w-[18px] text-current text-sm  hover:text-brand" />
          }
        />
      </nav>
      <nav className="flex flex-col border-t border-light-400 dark:border-dark-400 pt-1">
        {/* <GroupHeader title={'Explore'} href={'/'} icon={null} /> */}

        <div className={'py-1'}>
          <SubNavLink
            title={'Participants'}
            href={'/participants'}
            icon={<PeopleIcon className="h-[18px] w-[18px] text-current" />}
          />
          <SubNavLink
            title={'Winners'}
            href={'/winners'}
            icon={<TrophyIcon className="h-[18px] w-[18px] text-current" />}
          />
          <SubNavLink
            title={'Broadcasts'}
            href={'/broadcasts'}
            icon={<BroadcastIcon className="h-[18px] w-[18px] text-current" />}
          />
          <SubNavLink
            title={'Star Points'}
            href={'/starpoints'}
            icon={<StarPointsIcon className="h-[18px] w-[18px] text-current" />}
          />
          <SubNavLink
            title={
              <>
                Social&nbsp;<span className="text-orange-400">HUB</span>
              </>
            }
            href={'/socialhub'}
            icon={<SocialHubIcon className="h-[18px] w-[18px] text-current" />}
          />

          <SubNavLink
            title={'Merchandise'}
            href={'/merchandise'}
            icon={
              <ShoppingBagsIcon className="h-[18px] w-[18px] text-current" />
            }
          />
          <SubNavLink
            title={'Leaderboards'}
            href={'/leaderboards'}
            icon={
              <LeaderboardIcon className="h-[18px] w-[18px] text-current" />
            }
          />
        </div>
      </nav>
      <nav className="flex flex-col pb-1 ">
        {/* <GroupHeader title={'More From A4G'} href={'/'} icon={null} /> */}
        <div className={'py-1'}>
          <SubNavLink
            title={'Rewards Centre'}
            href={'/rewardscentre'}
            icon={<MedalIcon className="h-[18px] w-[18px] text-current" />}
          />
          {/* <SubNavLink
            title={'Download Our App'}
            href={'/'}
            icon={<DownloadIcon className="h-[18px] w-[18px] text-current" />}
          /> */}
          <SubNavLink
            title={'Newswire'}
            href={'/newswire'}
            icon={
              <NewspaperOutlineIcon className="h-[18px] w-[18px] text-current" />
            }
          />
          {/* <SubNavLink
            title={'Help & Support'}
            href={'/support'}
            icon={<HelpIcon className="h-[18px] w-[18px] text-current" />}
          /> */}
        </div>
      </nav>
    </div>
  );
};

export default MenuItems;
