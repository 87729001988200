
export const GiftBoxIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="black"
      stroke="black"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
      strokeWidth="4"
        d="M18 5h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C8.4.842 6.949 0 5.5 0A3.5 3.5 0 0 0 2 3.5c.003.52.123 1.033.351 1.5H2a2 2 0 0 0-2 2v3a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a2 2 0 0 0-2-2ZM8.058 5H5.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM11 13H9v7h2v-7Zm-4 0H2v5a2 2 0 0 0 2 2h3v-7Zm6 0v7h3a2 2 0 0 0 2-2v-5h-5Z"

      />
    </svg>
  );
};


export const GiftBoxIcon2: React.FC<React.SVGAttributes<{}>> = (props) => {

  return (
    <svg viewBox="0 0 64 80" fill="currentColor" stroke="black" {...props}>
      <path strokeWidth="0.1" d="M19 7V5.41l-2 2V14h2V7zM29 6h3V4H21v2h8zM44 7.41l-2-2V14h2V7.41zM40 6V4h-4.59l2 2H40zM36 7.41l-2-2V14h2V7.41zM31 35h11v7H31zM33 44h9v16h-9zM50 35h11v7H50zM6.43 60h15.14L14 55.19 6.43 60zM59 44h-9v16h9V44zM48 35h-4v7h4v-7zM43 29a2 2 0 0 0 0 4h2v-2a2 2 0 0 0-2-2ZM44 44h4v16h-4zM51 31a2 2 0 0 0-4 0v2h2a2 2 0 0 0 2-2Z" />
      <path strokeWidth="0.1" d="M13 25a1 1 0 0 1 2 0v28.45l8.92 5.68L22 16H6.94L4.13 59.1 13 53.45ZM31 60V44h-1a1 1 0 0 1-1-1v-9a1 1 0 0 1 1-1h9.56a3.91 3.91 0 0 1-.56-2 4 4 0 0 1 7-2.62A4 4 0 0 1 49 27a4 4 0 0 1 3.63 2.33L52 16H24l2 44ZM52.79 33v-.67a4.12 4.12 0 0 1-.32.67Z" />

    </svg>
  );
}


export const GiftBoxIcon3: React.FC<React.SVGAttributes<{}>> = (props) => {

  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <path d="M22 10.92l-2.74-1.59c2.64-2.25-.01-6.45-3.18-5.02l-.87.37-.11-.96c-.1-1.08-.66-1.85-1.4-2.3-1.64-.953-4.14-.3-4.54 2.08L6.41 1.92c-.96-.56-2.18-.23-2.73.73l-1 1.73c-.28.48-.11 1.09.37 1.37l7.79 4.5 1.5-2.6 1.73 1-1.5 2.6 7.79 4.5c.48.25 1.1.11 1.37-.37l1-1.73c.55-.96.23-2.18-.73-2.73M12.37 5c-.87.25-1.57-.68-1.13-1.45.26-.48.89-.64 1.37-.37.77.45.62 1.61-.24 1.82m5.19 3c-.86.25-1.56-.68-1.12-1.45.27-.48.89-.64 1.36-.37.77.45.62 1.61-.24 1.82m3.31 8.88c.41 0 .8-.14 1.13-.38V20c0 1.11-.89 2-2 2H4a2 2 0 01-2-2v-9h8.15l.85.5V20h2v-7.35l6.87 3.96c.3.18.63.27 1 .27z" />
    </svg>
  );
}



export const GiftBoxIcon4: React.FC<React.SVGAttributes<{}>> = (props) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      fill="currentColor"
      viewBox="0 0 48 60"
      {...props}
    >
      <path d="M27 19.245V45H14a1 1 0 0 1-1-1V33.244l14-13.999zM44 19H31v26h13a1 1 0 0 0 1-1V20a1 1 0 0 0-1-1zM6.513 23.245l3.059-3.059c-.182.09-.365.179-.542.255-2.242.962-4.598 1.208-6.001.627-.485-.201-.802-.48-.943-.831-.523-1.308 1.319-3.806 4.798-5.299 2.602-1.116 4.87-1.106 6.037-.601l1.25 1.25 1.414-1.414-1.25-1.25c-.505-1.168-.515-3.436.601-6.037 1.493-3.48 3.991-5.321 5.298-4.798.351.141.631.457.832.943.581 1.404.335 3.759-.627 6.001-.076.177-.165.36-.255.542l3.059-3.059c.251-1.604.152-3.088-.328-4.25-.409-.988-1.079-1.691-1.937-2.035-2.565-1.026-6.026 1.55-7.879 5.866-.9 2.098-1.245 4.231-1.026 5.978-1.746-.219-3.879.126-5.977 1.026C1.78 14.953-.796 18.414.23 20.979c.343.858 1.046 1.528 2.034 1.937 1.161.481 2.645.58 4.249.329zm8.365-5.536L4.293 28.294a.999.999 0 0 0 0 1.414l4 4a.999.999 0 0 0 1.414 0l10.586-10.586-5.415-5.413zm18.829-9.415-4-4a.999.999 0 0 0-1.414 0L17.707 14.88l5.414 5.414L33.707 9.708a.999.999 0 0 0 0-1.414z" />
    </svg>
  );
}

export const GiftBoxIcon6: React.FC<React.SVGAttributes<{}>> = (props) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 100 125"
      {...props}
      fill="currentColor"
    >
      <path d="m84.329 31.9 6.512-1.945v-5.947c-4.291-2.295-13.62-7.317-17.88-9.595-3.04.908-11.871 3.545-14.774 4.405 4.238 2.28 13.6 7.3 17.869 9.595v5.953c1.88-.566 6.069-1.816 7.983-2.385a2.72 2.72 0 0 1 .29-.081ZM45.024 8.1l-4.373 1.306 11.121 5.974h.011c.454.244 3.03 1.622 3.417 1.837a.423.423 0 0 0 .1.054l.505.268c2.867-.844 11.787-3.509 14.786-4.405-2.68-1.44-12.322-6.609-15.166-8.134Z" />
      <path d="M72.284 29.542a1.6 1.6 0 0 0-.892-1.44c-3.231-1.725-12.73-6.848-15.827-8.5-3.368 1.015-14.456 4.3-17.987 5.361l-.161.065a.749.749 0 0 0-.258.139 1.09 1.09 0 0 0-.247.205.868.868 0 0 0-.14.15.8.8 0 0 0-.172.29.557.557 0 0 0-.075.183.042.042 0 0 0-.022.043 1.191 1.191 0 0 0-.053.2 2.047 2.047 0 0 0-.022.268v5.416c2.078 1.116 8.077 4.331 10.24 5.491a1.841 1.841 0 0 1 .237.139l6.6 3.535c2.369-.707 16.79-5.006 18.782-5.608Z" />
      <path d="M69.157 86.189V39.782c-2.289.669-13.088 3.95-15.6 4.631a1.543 1.543 0 0 1-.935-.14c-.022-.011-.043-.011-.065-.022l-7.177-3.857c-2.246-.618-8.817-2.426-10.96-3.009v51.888c1.744.471 18.637 5.12 20.845 5.727 2.372-1.5 11.707-7.429 13.892-8.811ZM74.143 38.3v44.72c1.984-1.254 6.863-4.35 8.9-5.641V35.645c-2.059.61-6.9 2.055-8.9 2.655ZM32.313 29.724a.757.757 0 0 1 .086.043l.849.451v-5.942c4.616-1.38 15.374-4.583 19.942-5.952l-4.029-2.171h-.011l-11.121-5.974c-4.562 1.361-15.344 4.575-19.953 5.953v5.953l1.3.7a2.08 2.08 0 0 1 .215.118c1.692.906 11.267 6.046 12.722 6.821ZM9.159 30.43v51.89c2.322.639 18.4 5.058 20.27 5.58V36.01c-2.073-.561-7.979-2.2-10.13-2.79v-6.56Z" />
    </svg>
  );
}

export const GiftBoxIcon5: React.FC<React.SVGAttributes<{}>> = (props) => {

  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      fillRule="evenodd"
      clipRule="evenodd"
      viewBox="0 0 265 346.25"
      fill="currentColor"
      stroke="black"
    >

      <path
      strokeWidth="1"
        d="M168 101h90c4 0 7 3 7 7v25c0 4-3 7-7 7h-90v-39zM7 101h91v39H7c-4 0-7-3-7-7v-25c0-4 3-7 7-7zM113 269V99c0-4 4-7 8-7h24c4 0 7 3 7 7v170c0 4-3 8-7 8h-24c-4 0-8-3-8-8zM248 155v97c0 10-8 18-18 18h-62V155h80zM98 155v115H35c-9 0-17-8-17-18v-97h80zM111 86C71 93 35 88 4 73l9-9V51c17 11 55 33 98 35zM199 10c13 13 13 35 0 49-13 12-45 12-64 14 3-18 2-50 15-63 14-13 35-13 49 0zm-42 42c12-1 24-3 28-7 6-6 6-15 0-21s-15-6-21 0c-4 3-6 16-7 28zM76 23c-11 10-11 28 0 39 10 10 36 10 51 11-2-15-1-40-12-50-11-11-28-11-39 0zm34 33c-10-1-20-2-23-5-5-5-5-13 0-18 5-4 13-4 17 0 3 3 5 14 6 23zM155 86c39 7 75 2 106-13l-9-9c0-4 1-9 1-13-17 11-56 33-98 35z"
      />

    </svg>
  );
}





