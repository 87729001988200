import * as React from "react";
export const HomeIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.5702 7.95923C16.5698 7.95886 16.5695 7.9585 16.5691 7.95813L10.0417 1.43164C9.76348 1.15332 9.39357 1 9.0001 1C8.60663 1 8.23672 1.1532 7.95837 1.43152L1.43439 7.95471C1.43219 7.95691 1.42999 7.95923 1.42779 7.96143C0.856447 8.53601 0.857424 9.46826 1.4306 10.0414C1.69247 10.3033 2.03833 10.4551 2.40812 10.4709C2.42314 10.4724 2.43827 10.4731 2.45353 10.4731H2.71369V15.2762C2.71369 16.2267 3.48709 17 4.43787 17H6.99159C7.25041 17 7.46039 16.7902 7.46039 16.5312V12.7656C7.46039 12.3319 7.81321 11.9791 8.24697 11.9791H9.75323C10.187 11.9791 10.5398 12.3319 10.5398 12.7656V16.5312C10.5398 16.7902 10.7497 17 11.0086 17H13.5623C14.5131 17 15.2865 16.2267 15.2865 15.2762V10.4731H15.5277C15.9211 10.4731 16.291 10.3199 16.5695 10.0416C17.1433 9.46753 17.1435 8.53369 16.5702 7.95923Z"

      />
    </svg>
  );
};

// icon:home | Ionicons https://ionicons.com/ | Ionic Framework


export function HomeIcon2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 512 512"
      fill="currentColor"

      {...props}
    >
      <path d="M261.56 101.28a8 8 0 00-11.06 0L66.4 277.15a8 8 0 00-2.47 5.79L63.9 448a32 32 0 0032 32H192a16 16 0 0016-16V328a8 8 0 018-8h80a8 8 0 018 8v136a16 16 0 0016 16h96.06a32 32 0 0032-32V282.94a8 8 0 00-2.47-5.79z" />
      <path d="M490.91 244.15l-74.8-71.56V64a16 16 0 00-16-16h-48a16 16 0 00-16 16v32l-57.92-55.38C272.77 35.14 264.71 32 256 32c-8.68 0-16.72 3.14-22.14 8.63l-212.7 203.5c-6.22 6-7 15.87-1.34 22.37A16 16 0 0043 267.56L250.5 69.28a8 8 0 0111.06 0l207.52 198.28a16 16 0 0022.59-.44c6.14-6.36 5.63-16.86-.76-22.97z" />
    </svg>
  );
}


