// icon:tiktok | Bootstrap https://icons.getbootstrap.com/ | Bootstrap
import * as React from "react";

export const TiktokIcon: React.FC<React.SVGAttributes<{}>> = (props) => (
  <svg
    fill="currentColor"
    viewBox="0 0 16 16"
    height="1em"
    width="1em"
    {...props}
  >
    <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 11-5-5v2a3 3 0 103 3V0z" />
  </svg>
)

