export const ChatIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.9972 17.4988C10.9972 16.2098 11.3723 15.0085 12.0193 13.9979H6.24934C5.00706 13.9979 4 15.005 4 16.2473V17.1675C4 17.7397 4.17844 18.2976 4.51047 18.7636C5.9132 20.7323 8.12722 21.7976 11.0903 21.9744L11.6119 20.2613C11.2175 19.422 10.9972 18.485 10.9972 17.4988ZM11.9981 2C14.7601 2 16.9991 4.23907 16.9991 7.0011C16.9991 9.76314 14.7601 12.0022 11.9981 12.0022C9.23611 12.0022 6.99707 9.76314 6.99707 7.0011C6.99707 4.23907 9.23611 2 11.9981 2ZM23 17.4988C23 20.537 20.5371 23 17.4989 23C16.5312 23 15.6219 22.7502 14.832 22.3115L12.6449 22.977C12.2621 23.0935 11.9043 22.7357 12.0209 22.3529L12.6866 20.1664C12.2477 19.3763 11.9977 18.4667 11.9977 17.4988C11.9977 14.4605 14.4607 11.9976 17.4989 11.9976C20.5371 11.9976 23 14.4605 23 17.4988ZM15.4985 15.9985C15.2223 15.9985 14.9984 16.2224 14.9984 16.4986C14.9984 16.7748 15.2223 16.9987 15.4985 16.9987H19.4993C19.7755 16.9987 19.9994 16.7748 19.9994 16.4986C19.9994 16.2224 19.7755 15.9985 19.4993 15.9985H15.4985ZM14.9984 18.499C14.9984 18.7752 15.2223 18.9991 15.4985 18.9991H17.4989C17.7751 18.9991 17.999 18.7752 17.999 18.499C17.999 18.2228 17.7751 17.9989 17.4989 17.9989H15.4985C15.2223 17.9989 14.9984 18.2228 14.9984 18.499Z" fill="currentColor"/>
    </svg>
  );
};


