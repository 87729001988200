// icon:bar-chart-fill | Remix Icon https://remixicon.com/ | Remix Design
import * as React from "react";

export function LeaderboardIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M2 13h6v8H2v-8zm14-5h6v13h-6V8zM9 3h6v18H9V3zM4 15v4h2v-4H4zm7-10v14h2V5h-2zm7 5v9h2v-9h-2z" />
  </svg>
  );
}

