// icon:trophy-award | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from "react";

export function StarPointsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <path d="M15.2 10.7l1.4 5.3-4.6-3.8L7.4 16l1.4-5.2-4.2-3.5L10 7l2-5 2 5 5.4.3-4.2 3.4M14 19h-1v-3l-1-1-1 1v3h-1c-1.1 0-2 .9-2 2v1h8v-1a2 2 0 00-2-2z" />
    </svg>
  );
}

