'use client';
import { useContext, useState } from 'react';
import Button from '@/app/components/ui/button';
import { CartIcon } from '@/app/components/icons/cart-icon';
import { CartContext } from '@/app/components/cart/cart-context';
import CartItemList from '@/app/components/cart/cart-item-list';
import RightSlideDrawer from '@/app/components/layouts/_right-slide-drawer';
import Link from 'next/link';

export default function CartButton({ className }: { className?: string }) {
  const [open, setOpen] = useState(false);
  const [cart, dispatch] = useContext(CartContext);
  const isEmpty = (cart?.contents?.itemCount || 0) == 0;

  return (
    <>
      <Button
        variant="icon"
        aria-label="Cart"
        onClick={() => setOpen(true)}
        className={className}
      >
        <span className="relative flex items-center">
          <CartIcon className="h-5 w-5 md:h-6 md:w-6 text-dark-800 dark:text-white hover:text-brand dark:hover:text-brand" />
          {cart?.contents?.itemCount > 0 && (
            <>
              <span className="absolute -top-3 -right-2.5 flex min-h-[20px] min-w-[20px] shrink-0 items-center justify-center rounded-full  bg-brand px-0.5 text-10px leading-none text-dark">
                {cart?.contents?.itemCount || 0}
              </span>
              <span className="animate-ping absolute -top-3 -right-2.5 flex min-h-[20px] min-w-[20px] shrink-0 items-center justify-center rounded-full  bg-brand px-0.5 text-10px leading-none text-dark opacity-75"></span>
            </>
          )}
        </span>
      </Button>
      <RightSlideDrawer title={'Your cart'} open={open} setOpen={setOpen}>
        <div className="mt-4 px-4 sm:px-6 h-full flex-col overflow-y-scroll ">
          <CartItemList />
        </div>
        <div className="border-t border-light-300 px-3 py-6 dark:border-dark-500 sm:px-6 sm:pb-8 sm:pt-6">
          {cart?.subtotal > 0 && (
            <div className="flex justify-between text-sm font-medium text-dark dark:text-light">
              <span>{'Subtotal'}:</span>
              <span>{cart?.subtotal || ''}</span>
            </div>
          )}

          {!isEmpty && (
            <button className="mt-5 md:mt-8 flex rounded-lg justify-center items-center w-full text-sm md:h-[52px] bg-brand text-black hover:font-bold uppercase cursor-pointer">
              <Link
                onClick={() => setOpen(false)}
                href="/checkout"
                className=""
              >
                Proceed to checkout
              </Link>
            </button>
          )}
        </div>
      </RightSlideDrawer>
    </>
  );
}
