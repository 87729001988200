export const FacebookIcon: React.FC<React.SVGAttributes<{}>> = (props) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.8439 0H2.15615C0.965434 0 0.00012207 0.965273 0.00012207 2.15603V13.8439C0.00012207 15.0346 0.965395 15.9999 2.15615 15.9999H7.92051L7.93034 10.2824H6.44493C6.25189 10.2824 6.09521 10.1263 6.09447 9.93329L6.08735 8.0903C6.0866 7.8962 6.24375 7.73847 6.43785 7.73847H7.92055V5.95767C7.92055 3.89107 9.1827 2.7658 11.0262 2.7658H12.539C12.7326 2.7658 12.8895 2.92271 12.8895 3.1163V4.67031C12.8895 4.86382 12.7327 5.0207 12.5392 5.02081L11.6108 5.02124C10.6083 5.02124 10.4141 5.49766 10.4141 6.19682V7.73851H12.6171C12.827 7.73851 12.9899 7.9218 12.9652 8.13026L12.7467 9.97325C12.7258 10.1496 12.5763 10.2825 12.3987 10.2825H10.424L10.4141 16H13.844C15.0347 16 16 15.0347 16 13.844V2.15603C16 0.965273 15.0347 0 13.8439 0Z"
      fill="currentColor"
    />
  </svg>
);
