export const FacebookIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 10.0001C20 4.4767 15.5234 0.000137329 10 0.000137329C4.47656 0.000137329 0 4.4767 0 10.0001C0 15.5236 4.47656 20.0001 10 20.0001C10.0586 20.0001 10.1172 20.0001 10.1758 19.9962V12.215H8.02734V9.71107H10.1758V7.86732C10.1758 5.73061 11.4805 4.56654 13.3867 4.56654C14.3008 4.56654 15.0859 4.63295 15.3125 4.6642V6.89857H14C12.9648 6.89857 12.7617 7.39076 12.7617 8.11342V9.70717H15.2422L14.918 12.2111H12.7617V19.6134C16.9414 18.4142 20 14.5665 20 10.0001Z"
        fill="currentColor"
      />
    </svg>
  );
};
