import React from 'react';
import { YouTubeIcon } from '@/app/components/icons/social';
import { FacebookIcon } from '../icons/facebook-icon';
import { TiktokIcon } from '../icons/social/tik-tok';
import Link from 'next/link';

const Footer = () => {
  return (
    <div className="hidden lg:flex flex-col items-center bg-dark-200">
      <div className="grid lg:grid-cols-3 xl:grid-cols-4 space-y-9 w-[99%] ">
        <div className="text-[#6d6d6d] ">
          <div className="block justify-start md:mt-10 px-5 xl:px-10">
            <div
              className="flex  pb-3 "
              style={{ width: '219px', height: '88px', position: 'relative' }}
            >
              <img
                src="/images/logo2.png"
                alt=""
                style={{ objectFit: 'fill' }}
              />
            </div>
            <h1 className="uppercase text-white">About our company</h1>
            <p className="space-x-3 text-13px py-2 text-[#cacaca]">
              All For Giveaways provides entrants from across the UK, an
              opportunity and chance to win the latest in-demand prizes at the
              greatest odds possible.
            </p>
            <div
              className="flex my-2"
              style={{ height: '34px', width: '320px', position: 'relative' }}
            >
              <img
                src="/images/payvia.png"
                alt=""
                style={{ objectFit: 'fill' }}
              />
            </div>
          </div>
        </div>
        <div className="md:text-[15px] font-[550] leading-7 tracking-wide text-[#747474] w-[95%] ">
          <div>
            <pre className="pb-[34px] font-sans font-normal uppercase tracking-wider text-white underline decoration-orange-500 underline-offset-[12px]">
              A4G
            </pre>
          </div>
          <p className="mb-0">
            <Link
              href="/help"
              className="text-[#cacaca] hover:underline hover:text-brand"
            >
              Get Support
            </Link>
          </p>
          <p className="mb-0">
            <Link
              href="/howtoplay"
              className="text-[#cacaca] hover:underline hover:text-brand"
            >
              How To Play
            </Link>
          </p>
          <p className="mb-0">
            <Link
              href="/newswire"
              className="text-[#cacaca] hover:underline hover:text-brand"
            >
              Newswire
            </Link>
          </p>
          <p className="mb-0">
            <Link
              href="/mya4g/mydashboard"
              className="text-[#cacaca] hover:underline hover:text-brand"
            >
              MyA4G
            </Link>
          </p>

          <div className="flex h-[60px] mt-8">
            <div
              className="flex items-center"
              style={{ height: '60px', width: '60px', position: 'relative' }}
            >
              <img
                src="/images/jsvc.jpg"
                alt=""
                style={{ objectFit: 'fill' }}
              />
            </div>
            <span className="uppercase text-white ml-4 text-lg flex items-center h-[60px]">
              A J&S Venture Capital Company
            </span>
          </div>
        </div>
        <div className="flex-col justify-between md:flex lg:w-[95%] xl:w-[90%]">
          <div className="text-[15px] font-[550] leading-7 tracking-wide text-[#747474]">
            <div className="flex justify-start">
              <h1 className="pb-[34px] font-sans font-normal uppercase tracking-wider text-white underline decoration-orange-500 underline-offset-[12px]">
                Terms Of Play
              </h1>
            </div>
            <p className="mb-2">
              <Link
                href="/terms"
                className="text-[#cacaca] hover:underline hover:text-brand"
              >
                Terms & Conditions
              </Link>
            </p>
            <p className="mb-2">
              <Link
                href="/privacy"
                className="text-[#cacaca] hover:underline hover:text-brand"
              >
                Privacy Policy
              </Link>
            </p>
            <p className="mb-2">
              <Link
                href="/cookie-policy"
                className="text-[#cacaca] hover:underline hover:text-brand"
              >
                Cookie Policy
              </Link>
            </p>
          </div>
          <div className="mt-9  leading-6 tracking-wide text-[#747474] md:order-5 md:pt-32 lg:pb-28">
            <div className="flex justify-start md:-mt-32">
              <h1 className="pb-[8px] text-[15px] font-[550] font-sans  uppercase tracking-wider text-white ">
                Join Newsletter
              </h1>
            </div>
            <p className="text-13px space-x-3 pb-1 text-[#cacaca]">
              Be the first to know about our upcoming prize draw competitions,
              offers and news
            </p>
            <div className="flex pr-3">
              <input
                type="email"
                placeholder="Enter E-mail Here"
                className="py-1 px-2 text-15px placeholder-gray-400 focus: outline-none focus:bordermd:w-1/2 lg:w-3/5 border"
              />{' '}
              <button className="text-white bg-orange-500 p-2 px-4   hover:bg-orange-600">
                {' '}
                Subscribe{' '}
              </button>
            </div>
          </div>
        </div>
        <div className="lg:leading-6 tracking-wide text-[#797878] md:order-4 w-[95%] hidden xl:block">
          <div className="flex justify-start text-[15px] font-[550] ">
            <h1 className="pb-[38px] font-sans font-normal uppercase tracking-wider text-white underline decoration-orange-500 underline-offset-[12px]">
              Legal Notices
            </h1>
          </div>

          <div
            className="flex  pb-3"
            style={{ width: '161px', height: '68px', position: 'relative' }}
          >
            <img src="/images/rgc.png" alt="" style={{ objectFit: 'fill' }} />
          </div>
          <div
            className="flex my-4 "
            style={{ width: '231px', height: '30px', position: 'relative' }}
          >
            <img
              src="/images/begambleaware-1024x133.png"
              alt=""
              style={{ objectFit: 'fill' }}
            />
          </div>
          <p className="space-x-3 text-red-500/90 text-13px font-normal uppercase">
            <span className="text-warning">⚠</span> You must at least 18 or
            over to create an account and participate within our weekly
            giveaways.
          </p>
        </div>
      </div>
      <div className="hidden lg:flex xl:hidden w-[95%] gap-8 mb-8">
        {/* <div className="block justify-start text-[15px] font-[550] ">
          <h1 className="pb-[38px] font-sans font-normal uppercase tracking-wider text-white underline decoration-orange-500 underline-offset-[12px]">
            Legal Notices
          </h1>
        </div> */}

        <div className="flex h-[80px] pb-3">
          <img src="/images/rgc.png" alt="" />
        </div>
        <div className="flex my-4 h-[30px]">
          <img src="/images/begambleaware-1024x133.png" alt="" />
        </div>
        <p className="space-x-3 text-red-500/90 text-13px font-normal uppercase">
          <span className="text-warning">⚠</span> You must at least 18 or over
          to create an account and participate within our weekly giveaways.
        </p>
      </div>

      <div className="mt-3 mb-3 w-full divide-y divide-[#747474] contrast-200 md:-mt-4 md:-mb-2 lg:-mt-16 xl:-mt-24">
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
      <div className="pb-4 grid grid-cols-3 text-[#afafaf] md:mb-1">
        <div className="text-13px mx-4">
          © Copyright 2023. All rights reserved. All For Giveaways is a UK
          trading brand of J&S Venture Capital Holdings Ltd. <br />
          Version 2.5.0
        </div>
        {/* Social icons */}
        <div className="mb-4 flex justify-center space-x-3 text-center w-auto">
          <p className="mb-0">
            <a
              href="#"
              className=" h-10 w-10 flex justify-center transform rounded-full pt-[10px] duration-500 ease-in-out bg-white hover:text-white text-dark hover:bg-brand"
            >
              <YouTubeIcon className="h-5 w-5" />
            </a>
          </p>
          <p className="mb-0">
            <a
              href="#"
              className=" h-10 w-10 flex justify-center transform rounded-full pt-[10px] duration-500 ease-in-out bg-white hover:text-white text-dark hover:bg-brand"
            >
              <FacebookIcon className="h-5 w-5" />
            </a>
          </p>
          <p className="mb-0">
            <a
              href="#"
              className=" h-10 w-10 flex justify-center transform rounded-full pt-[10px] duration-500 ease-in-out bg-white hover:text-white text-dark hover:bg-brand"
            >
              <TiktokIcon className="h-5 w-5" />
            </a>
          </p>
        </div>
        <div className="mx-4 space-x-3 text-white  font-normal uppercase">
          Responsible Gaming.
          <br />
          Play responsibly and play within your limits.
        </div>
      </div>
    </div>
  );
};

export default Footer;
